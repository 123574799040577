const data = {
  products: [
    {
    id: '1',
    name: 'Thunderstruck book',
    description: "5.5 x 8.5 inches, 38 pages perfect bound, mirrored foil Thunderstruck logo on front cover. Designed by thunderstruck collective members with an original +2,000 word essay by curator Dr. Jessi DiTillio.",
    price: 20,
    image: require("./../Shop/img/book_01.jpg"),
    },
    {
      id: '2',
      name: 'Thunderstruck book + 4 CMYK prints',
      description: "4 Limited Edition CMYK Screen Prints on paper (Supernova, VLA, Cabin Interior, & Cabin Exterior 5 x 7 inches each) + Book 5.5 x 8.5 inches, 38 pages perfect bound, mirrored foil Thunderstruck logo on front cover",
      price: 115,
      image: require("./../Shop/img/book_prints_set_01.jpg"),
    },
    {
      id: '3',
      name: 'Spellcasting Salt',
      description: "2 oz bottle of black spellcasting salt gathered under the solstice full moon. Ingredients include: Bonneville Salt Flat Salt, Black Lava Salt, Charcoal, Bourbon, Sand from Sun Tunnels, Iré Ayé, Glacier Water, Cayenne, Magic & more.",
      price: 18,
      image: require("./../Shop/img/salt_01.jpg"),
    },
    {
      id: '4',
      name: 'Spirit Lake Cloud Shirt Black',
      description: "Limited Edition Screen Print on cotton blend. Sizes available: Adult S, Adult M, Adult L, Adult XL, Adult XXL.",
      price: 25,
      image: require("./../Shop/img/cloud-shirt_black.jpg"),
      },
      {
        id: '5',
        name: 'Spirit Lake Cloud Shirt Blue',
        description: "Limited Edition Screen Print on cotton blend. Sizes available: Adult S, Adult M, Adult L, Adult XL, Adult XXL.",
        price: 25,
        image: require("./../Shop/img/cloud-shirt_blue.jpg"),
      },
      {
        id: '6',
        name: 'Spirit Lake Cloud CMYK print',
        description: "Limited Edition CMYK Screen Print on paper, 5 x 7 inches, signed and numbered.",
        price: 25,
        image: require("./../Shop/img/spirit_lake_01.jpg"),
      },
      {
        id: '7',
        name: 'Cabin CMYK print',
        description: "Limited Edition CMYK Screen Print on paper, 5 x 7 inches, signed and numbered.",
        price: 25,
        image: require("./../Shop/img/cabin_01.jpg"),
      },
      {
        id: '8',
        name: 'Cabin Interior CMYK print',
        description: "Limited Edition CMYK Screen Print on paper, 5 x 7 inches, signed and numbered.",
        price: 25,
        image: require("./../Shop/img/cabin-interior_01.jpg"),
      },
      {
        id: '8',
        name: 'Satellites CMYK print',
        description: "Limited Edition CMYK Screen Print on paper, 5 x 7 inches, signed and numbered.",
        price: 25,
        image: require("./../Shop/img/vla_01.jpg"),
      },
      {
        id: '9',
        name: 'Supernova CMYK print',
        description: "Limited Edition CMYK Screen Print on paper, 5 x 7 inches, signed and numbered.",
        price: 25,
        image: require("./../Shop/img/supernova_01.jpg"),
      },
      {
        id: '10',
        name: 'Tarot Reading CMYK print',
        description: "Limited Edition CMYK Screen Print on paper, 5 x 7 inches, signed and numbered.",
        price: 25,
        image: require("./../Shop/img/tarot_01.jpg"),
      },
      {
        id: '11',
        name: 'Spirit Lake Tarot and Cloud CMYK prints',
        description: "2 Limited Edition CMYK Screen Prints on paper, 5 x 7 inches, signed and numbered.",
        price: 45,
        image: require("./../Shop/img/cmyk_2set_01.jpg"),
      },
  ],
};

export default data;